import { keycloak } from '../../config/keycloak-config';
import {
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGIN_REQUEST,
    LOGOUT,
} from './actionTypes';

import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export function getMe(userInfo) {
    return (async dispatch => {
        dispatch(loginRequest());
        try {
            const { name, email, sub } = userInfo;
            const userCard = { name, email, sub };
            dispatch(loginSuccess(userCard));
        }
        catch (ex) {
            console.log('Token exists, but login failed!');
            dispatch(loginFailure());
        }
    });
}

export function loginRequest() {
    return { type: LOGIN_REQUEST };
}
export function loginSuccess(userCard) {
    return { type: LOGIN_SUCCESS, userCard };
}
export function loginFailure() {
    return { type: LOGIN_FAILURE };
}

export function logout() {
    var options = {
      redirectUri: window.location.protocol + '//' + window.location.host,
    };
    
    keycloak.logout(options);
    return { type: LOGOUT };
  }