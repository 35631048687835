import React from 'react';
import AddProduct from "../../components/product/addProduct"
import ListProduct from "../../components/product/listProduct"
import { Button } from 'bonsai-components-react';
import { getProductList, saveProduct, updateProduct } from '../../store/product/action';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './product.scss';
import { InlineNotification } from 'bonsai-components-react';

let isEditable = false;
let productToEdit = [];
let hideInfo = true;

export class product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      showPopup: false,
      successNotification: false,
      notificationMessage: "",
    }
  }
  componentDidMount() {
    this.props.getProductList();
  }
 
  togglePopup = (popUpType) => {
    if (popUpType === "AddPopup") {
      isEditable = false;
      productToEdit = [];
    }
    this.setState({
      showPopup: !this.state.showPopup,
    });

    hideInfo = true;
  }
  notificationMessage = () =>{
      if (this.props.errorMessage === "") {
        this.setState({
          showPopup: !this.state.showPopup,
          successNotification: true
        });
      }
      else {
        this.setState({ successNotification: false });
      }
  }
  handleSave = async (productdetails) => {
    let notificationMessageVal = "";
    hideInfo = false;
    if (isEditable) {
      await this.props.updateProduct(productdetails)
      this.notificationMessage();
      notificationMessageVal = "Product Updated Successfully";
    }
    else {
      await this.props.saveProduct(productdetails)
      this.notificationMessage();
      notificationMessageVal = "Product Saved Successfully";
    }
    this.setState({ notificationMessage: notificationMessageVal });
  }
  handleEdit = (productName) => {
    const { productList } = this.props;
    productToEdit = productList.find(item => item.productName === productName);
    isEditable = true;
    this.setState({
      successNotification: false,
      notificationMessage: ""
    });
    this.togglePopup();
  }
  handleAdd = () => {
    this.setState({
      successNotification: false,
      notificationMessage: ""
    });
    this.togglePopup("AddPopup")
  }
  render() {
    return (
      <div>
        <Button id="btnAddProduct"
          onClick={this.handleAdd} className="add-btn">Add Product</Button>

        {this.state.successNotification && (<div className="successnotification-message">
          <InlineNotification
            kind="success"
            title={this.state.notificationMessage}
            iconDescription="close"
            subtitle=""
            hideCloseButton={true}
          />
        </div>)
        }
        <ListProduct productList={this.props.productList} onEditClick={this.handleEdit} />
        {this.state.showPopup ?
          <AddProduct text='Close Me'
            closePopup={() => this.togglePopup()}
            handleProductSubmit={this.handleSave}
            productToEdit={productToEdit}
            isEditable={isEditable}
            errorMessage={this.props.errorMessage}
            hideInfo={hideInfo}
            productdetailsloading={this.props.productdetailsloading}
          />
          : false
        }
      </div>

    );
  }
}
product.propTypes = {
  getProductList: PropTypes.func,
  saveProduct: PropTypes.func,
  updateProduct: PropTypes.func

};

product.defaultProps = {
  getProductList: () => { },
  saveProduct: () => { },
  updateProduct: () => { }
}

const mapDispatchToProps = { getProductList, saveProduct, updateProduct };

const mapStateToProps = ({ product }) => {
  const { productList, productListLoading, productdetailsloading, errorMessage } = product;
  return {
    productList,
    productListLoading,
    productdetailsloading,
    errorMessage
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(product);

