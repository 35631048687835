import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom';

import { logout } from '../../store/login/action';
import { removeKeyCloakAuthToken } from '../../utils/utils';

import './header.scss';
import "../../assets/stylesheets/style.scss";
import usericon from "../../assets/images/user_32.png";
import downarrow from "../../assets/images/down_arrow_16.png";


class Header extends Component {

  logout = () => {
    this.props.dispatch(logout());
    removeKeyCloakAuthToken();
    this.props.client.resetStore();
  }

  render() {
    let user_Name = null;
    if (this.props.userInfo) {
      user_Name = this.props.userInfo.name;
    }
    return (
      <header className="app_header">
        <div className="app_header__logo">
          <div className="app_header__logo__cog">
            <p className="app_header__logo__org">Cognizant</p>
            <p className="app_header__logo__app">Zendesk Gateway</p>
          </div>
        </div>
        <div className="app_header__actions">
          <div className="app_header__actions__help">
            <span className="user_name">Hi, {user_Name ? user_Name : "Guest"} </span>
            <img src={usericon} alt="Profile" className="profile_pic" />           
            <div className="dropdown_menu">
              <img src={downarrow} alt="Menu" className="app_header__menu__button" />
              <div className="drop_pointer">
                <ul className="nav_menu">
                  <li><Link to="/products">Products</Link></li>
                  <li><Link to="/">Gateway Configurations</Link></li>
                  <li><Link to="#" onClick={() => logout()}>Logout</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

Header.defaultProps = {
  userInfo: {}
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user.userDetails,
  }
};

const mapDispatchToProps = {
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));