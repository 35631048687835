import fetch from 'isomorphic-fetch';

import checkStatus from './requests';
import keycloak from '../config/keycloak-config';
import logout from './logout';

const authFetch = async (url, config) => {
  const updateInterval = parseInt(
    process.env.REACT_APP_KEYCLOAK_UPDATE_TOKEN_INTERVAL_IN_SECONDS,
    10,
  );
  try {
    await keycloak.updateToken(updateInterval);
    const headers = {
      ...config.headers,
      Authorization: `bearer ${keycloak.token}`,
    };
    const authInit = { ...config, headers };
    const response = await fetch(url, authInit);
    return checkStatus(response);
  } catch (err) {
    logout();
    throw err;
  }
};

export default authFetch;
