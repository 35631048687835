import createReducer from '../createReducer';

import {
  CONFIGURATION_LIST_LOADING,
  CONFIGURATION_LIST_FAILURE,
  CONFIGURATION_LIST_SUCCESS,
  SAVE_CONFIGURATION_INPROGRESS,
  SAVE_CONFIGURATION_FAILURE,
  SAVE_CONFIGURATION_SUCCESS,
  UPDATE_CONFIGURATION_INPROGRESS,
  UPDATE_CONFIGURATION_FAILURE,
  UPDATE_CONFIGURATION_SUCCESS
} from "./actionTypes";

const initialState = {
  configurationList: [],
  configurationListLoading: false,
  configurationdetailsloading: false,
  errorMessage: ""
};

export default createReducer(initialState, {
  [CONFIGURATION_LIST_LOADING](state) {
    return { ...state, configurationListLoading: true };
  },
  [CONFIGURATION_LIST_FAILURE](state) { //<-- here the 'state' is the initialState declared above
    return { ...state, configurationList: [], configurationListLoading: false }; //<--if data loading failed, intialState will return
  },
  [CONFIGURATION_LIST_SUCCESS](state, action) {//<--'action' is the 'data' passing from action.js  
    return { ...state, configurationList: action.data, configurationListLoading: true};
  },
  [SAVE_CONFIGURATION_INPROGRESS](state) {
    return { ...state, errorMessage: "", configurationdetailsloading: true };
  },
  [SAVE_CONFIGURATION_FAILURE](state, response) { //<-- here the 'state' is the initialState declared above      
    return { ...state, errorMessage: response.message !== "" ? response.message : response.message.join(', '), configurationdetailsloading: false }; //<--if data loading failed, intialState will return
  },
  [SAVE_CONFIGURATION_SUCCESS](state, action) {//<--'action' is the 'data' passing from action.js      
    return {
      ...state,
      configurationList: action.data ? [...state.configurationList, action.data] : state.configurationList,
      configurationdetailsloading: false
    };
  },
  [UPDATE_CONFIGURATION_INPROGRESS](state) {
    return { ...state, configurationdetailsloading: true };
  },
  [UPDATE_CONFIGURATION_FAILURE](state) { //<-- here the 'state' is the initialState declared above
    return { ...state, configurationdetailsloading: false }; //<--if data loading failed, intialState will return
  },
  [UPDATE_CONFIGURATION_SUCCESS](state, action) {//<--'action' is the 'data' passing from action.js
    const { configurationList } = state;
    const { data: savedData } = action;

    const updatedconfigurationList = configurationList.filter(config => config.clientName !== savedData.clientName);

    updatedconfigurationList.push(savedData);

    return { ...state, configurationList: updatedconfigurationList, configurationdetailsloading: false };
  }
});