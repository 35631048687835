import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { keycloak } from './config/keycloak-config';
import thunk from 'redux-thunk';

import App from './app/AppContainer/App';
import rootReducer from './store/rootReducer';

import './assets/stylesheets/index.scss';
import { getMe } from './store/login/action';
import { setKeyCloakAuthToken } from './utils/utils';

const store = createStore(rootReducer, applyMiddleware(thunk));

const render = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <Component />
    </Provider>,
    document.getElementById('root')
  );
};

keycloak
  .init({ onLoad: 'login-required', checkLoginIframe: false })
  .success(authenticated => {
    if (authenticated) {
      setKeyCloakAuthToken(keycloak.token);
      keycloak.loadUserInfo().success(async res => {
        await store.dispatch(getMe(res));
        render(App);
      });
      setInterval(() => {
        keycloak.updateToken(10).error(() => keycloak.logout());
      }, 10000);
    } else {
      keycloak.logout();
    }
  });


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
