import authFetch from '../utils/authFetch';
import { GW_API_URL } from '../config/config';
class configuration {
  //to get the configuration list
  async getConfigurationList() {
    let response = [];
    const gatewaylistUrl = `${GW_API_URL}/gateway`;
    response = await authFetch(gatewaylistUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return response;
  }

  async saveConfiguration(gatewayConfigDetails) {
    let response = null;
    const gatewayAPIUrl = `${GW_API_URL}/gateway`;
    response = await authFetch(gatewayAPIUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(gatewayConfigDetails),
    });
    return response;
  }

  async updateConfiguration(gatewayConfigDetails) {
    let response = null;
    try {
      const gatewayAPIUrl = `${process.env.REACT_APP_GW_API_URL}/gateway`;
      response = await authFetch(gatewayAPIUrl, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json'
          },
          body:JSON.stringify(gatewayConfigDetails),
      });
    }
    catch (Ex) {
      console.log("Exception in updateconfiguration service: ", Ex);
    }
    return response;
  }
}
export default new configuration();