import React from 'react';
import { DataTable, Icon } from "bonsai-components-react";
import { iconEdit } from 'bonsai-icons';
import PropTypes from 'prop-types';

import "./listProduct.scss";

const {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow
} = DataTable

export class listProduct extends React.Component {
  render() {
    const productList = this.props.productList;
    const prodList = productList && productList
      .map(p => ({
        id: p.productName,
        productName: p.productName,
        productURL: p.productUrl
      }));
    prodList.sort(function (a, b) {
      if (a.productName < b.productName) return -1;
      else if (a.productName > b.productName) return 1;
      return 0;
    });


    const headers = [
      {
        key: 'productName',
        header: 'Product',
      },
      {
        key: 'productURL',
        header: 'Product URL',
      }
    ]

    return (
      <div className="datatablehead">
        <div className="headtag">Manage Product</div>
        <DataTable
          rows={prodList}
          headers={headers}
          render={({
            rows,
            headers,
            getHeaderProps,
          }) => (
              <TableContainer title="">
                <Table>
                  <TableHead>
                    <TableRow>
                      {headers && headers.map(header => (
                        <TableHeader {...getHeaderProps({ header })}>
                          {header.header}
                        </TableHeader>
                      ))}
                      <TableHeader />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows && rows.map(row => (
                      <TableRow key={row.id} >
                        {row.cells.map(cell => (
                          <TableCell key={cell.id}>{cell.value}</TableCell>
                        ))}
                        <TableCell>
                          <Icon
                            icon={iconEdit}
                            description="click here to edit"
                            fill="black"
                            className="action_icon"
                            onClick={(e) => {
                              this.props.onEditClick(row.id);
                              e.stopPropagation();
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
        />
      </div>
    );
  }
}

listProduct.propTypes = {
  productList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    productName: PropTypes.string,
    productURL: PropTypes.string
  })),
  onEditClick: PropTypes.func
};

export default listProduct;
