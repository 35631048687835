import {
  PRODUCT_LIST_LOADING,
  PRODUCT_LIST_FAILURE,
  PRODUCT_LIST_SUCCESS,
  SAVE_PRODUCT_INPROGRESS,
  SAVE_PRODUCT_FAILURE,
  SAVE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_INPROGRESS,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_SUCCESS
} from "./actionTypes";
import productService from "../../services/product";

export function productListLoading() {
  return { type: PRODUCT_LIST_LOADING }
};
export function productListFailure() {
  return { type: PRODUCT_LIST_FAILURE };
}
export function productListSuccess(data) {
  return { type: PRODUCT_LIST_SUCCESS, data };
}

export const getProductList = () => async (dispatch) => {
  try {
    dispatch(productListLoading());
    const productList = await productService.getProductList();
    dispatch(productListSuccess(productList));
  }
  catch (error) {
    console.log("error", error);
    dispatch(productListFailure());
  }

}

export const saveProductInProgress = () => ({ type: SAVE_PRODUCT_INPROGRESS });
export const saveProductFailure = (message) => ({ type: SAVE_PRODUCT_FAILURE, message });
export function saveProductSuccess(data) {
  return { type: SAVE_PRODUCT_SUCCESS, data };
}

export const saveProduct = (productData) => async (dispatch) => {
  try {
    dispatch(saveProductInProgress());
    const response = await productService.saveProduct(productData);
    if ((response.validationMsg && response.validationMsg !== "") || (response.error && response.error !== "")) {
      if (response.validationMsg)
        dispatch(saveProductFailure(response.validationMsg));
      else if (response.error)
        dispatch(saveProductFailure(response.error));
    }
    else
      dispatch(saveProductSuccess(response));
  }
  catch (error) {
    console.log("Error: ", error);
    dispatch(saveProductFailure(error.message));
  }
}

export const updateProductInProgress = () => ({ type: UPDATE_PRODUCT_INPROGRESS });
export const updateProductFailure = (message) => ({ type: UPDATE_PRODUCT_FAILURE, message });
export function updateProductSuccess(data) {
  return { type: UPDATE_PRODUCT_SUCCESS, data };
}

export const updateProduct = (productData) => async (dispatch) => {
  try {
    dispatch(updateProductInProgress());
    const response = await productService.updateProduct(productData);
    if ((response.validationMsg && response.validationMsg !== "") || (response.error && response.error !== "")) {
      if (response.validationMsg)
        dispatch(updateProductFailure(response.validationMsg));
      else if (response.error)
        dispatch(updateProductFailure(response.error));
    }
    else
      dispatch(updateProductSuccess(response));
  }
  catch (error) {
    console.log("Error: ", error);
    dispatch(updateProductFailure(error.message));
  }
}
