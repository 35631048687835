import React, { Component } from "react";
import { DataTable, Icon } from "bonsai-components-react";
import { iconEdit } from 'bonsai-icons';
import PropTypes from 'prop-types';
import "./listConfiguration.scss";

const {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow
} = DataTable

class ListConfiguration extends Component {
  render() {
    const configurationList = this.props.configurationList;
    const configList = configurationList && configurationList.map(p => ({
      id: p.clientName,
      productUrl: p.productUrl,
      clientName: p.clientName,
      keycloak: (
        <span>
        authurl: {p.keycloak.authurl}<br />
        realm: {p.keycloak.realm}<br />
        realmClient: {p.keycloak.realmClient}
        </span>)
      ,
      zendesk: (
        <span>
        baseUrl: { p.zendesk.baseUrl} <br />
        endpoints: {
          <span>
          [
            <br/>
              <pre>   type: {p.zendesk.endpoints[0].type}, url: {p.zendesk.endpoints[0].url}</pre>
              <pre>   type: {p.zendesk.endpoints[1].type}, url: {p.zendesk.endpoints[1].url}</pre>
              <pre>   type: {p.zendesk.endpoints[2].type}, url: {p.zendesk.endpoints[2].url}</pre>
          ]
          </span>
        }
      </span>
      )
    }));
    const headers = [
      {
        key: 'clientName',
        header: 'Client Name',
      },
      {
        key: 'productUrl',
        header: 'Product Url',
      }
      ,
      {
        key: 'keycloak',
        header: 'keycloak Details',
      },
      {
        key: 'zendesk',
        header: 'Zendesk Details'
      }
    ]
    return (
      <div className="datatablehead">
        <div className="headtag">Gateway Configuration</div>
        <DataTable
          rows={configList}
          headers={headers}
          render={({
            rows,
            headers,
            getHeaderProps,
          }) => (
              <TableContainer title="" size="standard">
                <Table>
                  <TableHead>
                    <TableRow>
                      {headers && headers.map(header => (
                        <TableHeader {...getHeaderProps({ header }) }>
                          {header.header}
                        </TableHeader>
                      ))}
                      <TableHeader />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows && rows.map(row => (
                      <TableRow key={row.id} >
                        {row.cells.map(cell => (
                          <TableCell key={cell.id}>{cell.value}</TableCell>
                        ))}
                        <TableCell>
                          <Icon
                            icon={iconEdit}
                            description="Click here to edit"
                            fill='#001E60'
                            className="action_icon"
                            onClick={(e) => {
                              this.props.onEditClick(row.id);
                              e.stopPropagation();
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
        />
      </div>
    );
  }
}
ListConfiguration.propTypes = {
  configurationList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    clientName: PropTypes.string,
    keycloak: PropTypes.object,
    zendesk: PropTypes.object,
  })),
  onEditClick: PropTypes.func
}


export default ListConfiguration;