import Keycloak from 'keycloak-js';

import {
  GW_KEYCLOAK_URL,
  GW_KEYCLOAK_REALM_NAME,
  GW_KEYCLOAK_CLIENT_NAME,
} from './config';

export const keycloak = Keycloak({
  realm: GW_KEYCLOAK_REALM_NAME,
  url: GW_KEYCLOAK_URL,
  'ssl-required': 'external',
  resource: GW_KEYCLOAK_CLIENT_NAME,
  clientId: GW_KEYCLOAK_CLIENT_NAME,
  'public-client': true,
});

export default keycloak;


