import authFetch from '../utils/authFetch';
class product {
  async getProductList() {    
    let response = [];
    try{
      const productlistUrl = `${process.env.REACT_APP_GW_API_URL}/product`;
      response = await authFetch(productlistUrl, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
      });
    }
    catch (Ex) {
      console.log("Exception in getProductList service: ", Ex);
    }    
    return response;
  }

  async saveProduct(product) {
    let response = null;
    try {
      const productlistUrl = `${process.env.REACT_APP_GW_API_URL}/product`;
      response = await authFetch(productlistUrl, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body:JSON.stringify(product),
      });  
    }
    catch (Ex) {
      console.log("Exception in saveProduct service: ", Ex);
    }
    return response;
  }



  async updateProduct(product) {
    let response = null;
    try {
      const productlistUrl = `${process.env.REACT_APP_GW_API_URL}/product`;
      response = await authFetch(productlistUrl, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json'
          },
          body:JSON.stringify(product),
      });
    }
    catch (Ex) {
      console.log("Exception in updateProduct service: ", Ex);
    }

    return response;
  }
}
export default new product();