import createReducer from '../createReducer';

import {
  PRODUCT_LIST_LOADING,
  PRODUCT_LIST_FAILURE,
  PRODUCT_LIST_SUCCESS,
  SAVE_PRODUCT_INPROGRESS,
  SAVE_PRODUCT_FAILURE,
  SAVE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_INPROGRESS,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_SUCCESS
} from "./actionTypes";

const initialState = {
  productList: [],
  productListLoading: false,
  productdetailsloading: false,
  errorMessage: ""
};

export default createReducer(initialState, {
  [PRODUCT_LIST_LOADING](state) {
    return { ...state, productListLoading: true };
  },
  [PRODUCT_LIST_FAILURE](state) { //<-- here the 'state' is the initialState declared above
    return { ...state, productList: [], productListLoading: false }; //<--if data loading failed, intialState will return
  },
  [PRODUCT_LIST_SUCCESS](state, action) {//<--'action' is the 'data' passing from action.js
    return { ...state, productList: action.data, productListLoading: true };
  },
  [SAVE_PRODUCT_INPROGRESS](state) {
    return { ...state,errorMessage:"", productdetailsloading: true };
  },
  [SAVE_PRODUCT_FAILURE](state, response) { //<-- here the 'state' is the initialState declared above
    return { ...state, errorMessage: response.message !== "" ? response.message : response.message.join(', '), productdetailsloading: false }; //<--if data loading failed, intialState will return
  },
  [SAVE_PRODUCT_SUCCESS](state, action) {//<--'action' is the 'data' passing from action.js    
    return {
      ...state,
      productList: action.data ? [...state.productList, action.data] : state.productList,
      productdetailsloading: false
    };
  },
  [UPDATE_PRODUCT_INPROGRESS](state) {
    return { ...state, errorMessage:"", productdetailsloading: true };
  },
  [UPDATE_PRODUCT_FAILURE](state, response) { //<-- here the 'state' is the initialState declared above
    return { ...state, errorMessage:response.message !== "" ? response.message : response.message.join(', '), productdetailsloading: false }; //<--if data loading failed, intialState will return
  },
  [UPDATE_PRODUCT_SUCCESS](state, action) {//<--'action' is the 'data' passing from action.js
    const { productList } = state;
    const { data } = action;

    const updatedproductList = productList.filter(prod => prod.productName !== data.productName);

    updatedproductList.push(data);

    return { ...state, productList: updatedproductList, productdetailsloading: false };
  }
});