import React from 'react';
import PropTypes from 'prop-types';
import { Button, InlineLoading, InlineNotification,TextInput } from 'bonsai-components-react';
import './addProduct.scss';

export class AddProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: [{
        productName: '',
        productUrl: '',
      }],
      errors: {},
      loadingShow: false,
    }
  }
  handleProductSubmit = event => {
    this.setState({ loadingShow: true });
    if (this.ProductValidate()) {
        var data = this.state.product;
        this.props.handleProductSubmit(data);
    }
    event.preventDefault();
  }
 
  ProductValidate() {
    let errors = {};
    let formIsValid = true;
    var urlRegx = "^(https?://)([-_a-z0-9]{1,63}\\.)*?[a-z0-9][-_a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
    var pattern = new RegExp(urlRegx, "i");
    var { product } = this.state;

    if (product && !product.productName) {
      formIsValid = false;
      errors["productName"] = "Product name required!";
    }

    if (product && !product.productUrl) {
      formIsValid = false;
      errors["productUrl"] = "Product URL required!";
    }

    if (product && product.productUrl && formIsValid) {
      if (!pattern.test(product.productUrl)) {
        formIsValid = false;
        errors["productUrl"] = "Invalid authentication URL";
      }
    }
    this.setState({
      errors: errors
    })
    return formIsValid;
  }
 
  componentDidMount() {
    this.setState({
      product: {
        ...this.state.product,
        productName: this.props.productToEdit.productName,
        productUrl: this.props.productToEdit.productUrl
      }
    });
  }
  render() {
    let notification = this.props.errorMessage !== "" ?  
      <div>
        <InlineNotification
          kind="error"
          title=""
          subtitle={this.props.errorMessage}
          iconDescription="close"
        />
      </div>
      : <div></div>;
    return (
      <div className='popup'>
        <div className='popup_inner'>
          <div className="modal-content">
            <span className="close" onClick={this.props.closePopup}>&times;</span>
            <h3 className="h3Style">{this.props.productToEdit.productName ? "Edit Product" : "Add Product"}</h3>
            <hr className="hrStyle" />
            <div className="row">
              <div className="label_popup">Product Name
                            <span className="astriks">*</span></div>
              <div className="textbox_popup">
                <TextInput id="txtProductname"
                  disabled={this.props.isEditable ? true : null}
                  defaultValue={this.props.productToEdit.productName} labelText=""
                  onChange={(e) =>
                    this.setState({
                      product: {
                        ...this.state.product,
                        productName: e.target.value,
                      },
                    })
                  } />
                <span className="validationError">{this.state.errors["productName"]}</span>
              </div>
            </div>
            <div className="row">
              <div className="label_popup">Product URL
                            <span className="astriks">*</span> </div>
              <div className="textbox_popup">
                <TextInput id="txtProductUrl" labelText=""
                  defaultValue={this.props.productToEdit.productUrl}
                  onChange={(e) =>
                    this.setState({
                      product: {
                        ...this.state.product,
                        productUrl: e.target.value
                      },
                    })
                  } />
                <span className="validationError">{this.state.errors["productUrl"]}</span>
              </div>
            </div>
            <div className="row_button">
              {this.props.hideInfo ? null : notification}
              <Button className="cancelbutton" onClick={this.props.closePopup}>Cancel</Button>
              <Button className="savebutton" onClick={this.handleProductSubmit}>Save</Button>
              { this.props.productdetailsloading ? <div><InlineLoading description="Please wait..." /></div> : <div></div> }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddProduct.propTypes = {
  closePopup: PropTypes.func,
  handleProductSubmit: PropTypes.func
};

export default AddProduct;
