import {
  CONFIGURATION_LIST_LOADING,
  CONFIGURATION_LIST_FAILURE,
  CONFIGURATION_LIST_SUCCESS,
  SAVE_CONFIGURATION_INPROGRESS,
  SAVE_CONFIGURATION_FAILURE,
  SAVE_CONFIGURATION_SUCCESS,
  UPDATE_CONFIGURATION_INPROGRESS,
  UPDATE_CONFIGURATION_FAILURE,
  UPDATE_CONFIGURATION_SUCCESS
} from "./actionTypes";
import configurationService from "../../services/configuration";

export function configurationListLoading() {
  return { type: CONFIGURATION_LIST_LOADING }
};
export function configurationListFailure() {
  return { type: CONFIGURATION_LIST_FAILURE }
};
export function configurationListSuccess(data) {
  return { type: CONFIGURATION_LIST_SUCCESS, data }
};

export const getConfigurationList = () => async (dispatch) => {
  try {
    dispatch(configurationListLoading());        
    const configurationList = await configurationService.getConfigurationList();        
    dispatch(configurationListSuccess(configurationList));
  }
  catch (error) {
    console.log("Error", error);
    dispatch(configurationListFailure());
  }
}

export const saveconfigurationInProgress = () => ({ type: SAVE_CONFIGURATION_INPROGRESS });
export const saveconfigurationFailure = (message) => ({ type: SAVE_CONFIGURATION_FAILURE, message });
export function saveconfigurationSuccess(data) {
  return { type: SAVE_CONFIGURATION_SUCCESS, data };
}

export const saveConfiguration = (configurationData) => async (dispatch) => {
  try {
    dispatch(saveconfigurationInProgress());
    const response = await configurationService.saveConfiguration(configurationData);
    if ((response.validationMsg && response.validationMsg !== "") || (response.error && response.error !== "")) {
      if (response.validationMsg)
        dispatch(saveconfigurationFailure(response.validationMsg));
      else if (response.error)
        dispatch(saveconfigurationFailure(response.error));      
    }
    else{
      dispatch(saveconfigurationSuccess(response));
    }
  }
  catch (error) {
    console.log("Error: ", error);
    dispatch(saveconfigurationFailure(error.message));
  }
}

export const updateconfigurationInProgress = () => ({ type: UPDATE_CONFIGURATION_INPROGRESS });
export const updateconfigurationFailure = (message) => ({ type: UPDATE_CONFIGURATION_FAILURE, message });
export function updateconfigurationSuccess(data) {
  return { type: UPDATE_CONFIGURATION_SUCCESS, data };
}

export const updateConfiguration = (configurationData) => async (dispatch) => {
  try {
    dispatch(updateconfigurationInProgress());
    const response = await configurationService.updateConfiguration(configurationData);
    dispatch(updateconfigurationSuccess(response));
  }
  catch (error) {
    console.log("Error: ", error);
    dispatch(updateconfigurationFailure(error.message));
  }
}
