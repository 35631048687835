import React, { Component } from "react";
import PropTypes from 'prop-types';
import { DropdownV2, TextInput, Button, InlineNotification, Icon, InlineLoading } from "bonsai-components-react";
import { iconInfoSolid } from 'bonsai-icons';

import './addConfiguration.scss';

export class AddConfiguration extends Component {
  constructor(props) {
    super(props);
    let initialproducts = [];
    initialproducts.splice(0, 0, { id: 0, text: '-- Select --' });

    this.state = {
      gatewayConfigDetails: {},
      selectedProduct: initialproducts[0],
      products: [],
      authUrl: '',
      realm: '',
      realmClient: '',
      baseUrl: '',
      defaultUrl: '',
      supportUrl: '',
      faqUrl: '',
      errors: {},
      loadingShow: false,
    }
  }

  productNameChanged = (e) => {
    const currentProductSelected = e.selectedItem.text;
    const currentProductSelectedValue = e.selectedItem.id;
    this.setState({
      selectedProduct: currentProductSelected,
      selectedProductValue: currentProductSelectedValue
    });
    if (currentProductSelectedValue === "-1") {
      this.props.history.push("/products");
    }
  }
  handleConfigurationSubmit = event => {
    this.setState({ loadingShow: true });
    var data = this.createConfigurationObject();
    if (data) {
      this.props.handleConfigurationSubmit(data);
    }
    event.preventDefault();
  }
  createConfigurationObject() {
    let gatewayConfigDetails = [];
    if (this.props.isEditable === true) {
      gatewayConfigDetails =
        {
          clientName: this.state.selectedProduct,
          productUrl: this.state.selectedProductValue,
          "keycloak":
          {
            authurl: this.state.authUrl,
            realm: this.state.realm,
            realmClient: this.state.realmClient,
          },
          "zendesk":
          {
            baseUrl: this.state.baseUrl,
            "endpoints": [
              {
                "type": "Default",
                "url": this.state.defaultUrl,
              },
              {
                "type": "Support",
                "url": this.state.supportUrl
              },
              {
                "type": "FAQ",
                "url": this.state.faqUrl
              }
            ]
          }
        }
      if (this.ConfigurationValidate(gatewayConfigDetails)) {
        return gatewayConfigDetails;
      }
      else {
        return null;
      }
    }
    else {
      gatewayConfigDetails =
        {
          clientName: this.state.selectedProduct,
          productUrl: this.state.selectedProductValue,
          "keycloak":
          {
            authurl: this.state.authUrl,
            realm: this.state.realm,
            realmClient: this.state.realmClient,
          },
          "zendesk":
          {
            baseUrl: this.state.baseUrl,
            "endpoints": [
              {
                "type": "Default",
                "url": this.state.defaultUrl,
              },
              {
                "type": "Support",
                "url": this.state.supportUrl
              },
              {
                "type": "FAQ",
                "url": this.state.faqUrl
              }
            ]
          }
        }
      if (this.ConfigurationValidate(gatewayConfigDetails)) {
        return gatewayConfigDetails;
      }
      else {
        return null;
      }
    }
  }

  convertStringToArray(text) {
    text = this.trimString(text);
    const arry = text.split(',');
    return arry;
  }

  trimString(text) {
    const temp = text && text.replace(/'|\[|\]/g, '');
    return temp;
  }

  convertArryToString(ary) {
    const str = ary && ary.join(',');
    return str;
  }

  checkDuplicate(arryRealm) {
    var duplicates = arryRealm.filter((item, index) => arryRealm.indexOf(item) !== index);    
    if (duplicates && duplicates.length > 0)
      return true;
    else
      return false;
  }

  ConfigurationValidate(gatewayConfigurationDetails) {
    let errors = {};
    let formIsValid = true;
    var urlRegx = "^(https?://)([-_a-z0-9]{1,63}\\.)*?[a-z0-9][-_a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
    var pattern = new RegExp(urlRegx, "i");
    if (gatewayConfigurationDetails.clientName === undefined || gatewayConfigurationDetails.clientName === '-- Select --') {
      formIsValid = false;
      errors["productId"] = "Product Name required!";
    }
    if (!gatewayConfigurationDetails.keycloak.authurl) {
      formIsValid = false;
      errors["authurl"] = "Authentication Url required!";
    }
    if (!gatewayConfigurationDetails.zendesk.baseUrl) {
      formIsValid = false;
      errors["baseUrl"] = "BaseUrl required!";
    }
    if (!gatewayConfigurationDetails.keycloak.realm) {
      formIsValid = false;
      errors["realm"] = "Realm required!";
    }
    if (!gatewayConfigurationDetails.keycloak.realmClient) {
      formIsValid = false;
      errors["realmClient"] = "Realm Client required!";
    }
    if (!gatewayConfigurationDetails.zendesk.endpoints[0].url) {
      formIsValid = false;
      errors["defaultUrl"] = "Default URL required!";
    }
    if (!gatewayConfigurationDetails.zendesk.endpoints[1].url) {
      formIsValid = false;
      errors["supportUrl"] = "Support URL required!";
    }
    if (!gatewayConfigurationDetails.zendesk.endpoints[2].url) {
      formIsValid = false;
      errors["faqUrl"] = "FAQ URL required!";
    }
    if (gatewayConfigurationDetails.keycloak.authurl) {
      if (!pattern.test(gatewayConfigurationDetails.keycloak.authurl)) {
        formIsValid = false;
        errors["authurl"] = "Invalid Authentication URL";
      }
    }
    if (gatewayConfigurationDetails.zendesk.baseUrl) {
      if (!pattern.test(gatewayConfigurationDetails.zendesk.baseUrl)) {
        formIsValid = false;
        errors["baseUrl"] = "Invalid Base URL";
      }
    }
    if (gatewayConfigurationDetails.keycloak.realm) {
      if (this.checkDuplicate(gatewayConfigurationDetails.keycloak.realm)) {
        formIsValid = false;
        errors["realm"] = "Duplicate realm names are not allowed!";
      }
    }
    this.setState({
      errors: errors
    })
    return formIsValid;
  }

  handleAuthUrlChange = (e) => {
    this.setState({ authUrl: e.target.value });
  }
  handleRealmChange = (e) => {
    this.setState({ realm: this.convertStringToArray(e.target.value) });
  }
  handleRealmClientChange = (e) => {
    this.setState({ realmClient: e.target.value });
  }
  handleBaseUrlChange = (e) => {
    this.setState({ baseUrl: e.target.value });
  }
  handleDefaultUrlChange = (e) => {
    this.setState({ defaultUrl: e.target.value });
  }
  handleSupportUrlChange = (e) => {
    this.setState({ supportUrl: e.target.value });
  }
  handleFaqUrlChange = (e) => {
    this.setState({ faqUrl: e.target.value });
  }

  componentDidMount() {
    let selectedProductItem;
    let productDBCollection = this.props.productList;
    let products = [];
    products = productDBCollection && productDBCollection.map(prd => {
      return { id: prd.productUrl, text: prd.productName }
    });
    products && products.splice(0, 0, { id: 0, text: '-- Select --' });
    products && products.splice(products.length + 1, 0, { id: '-1', text: '< Add New Product >' });
    if (this.props.isEditable === true) {
      selectedProductItem = this.props.gatewayToEdit && products && products.find(x => x.id === this.props.gatewayToEdit.productUrl.toString());
    }
    this.setState({
      products: products,
      selectedProductItem: selectedProductItem,
      selectedProduct: selectedProductItem && selectedProductItem.text,
      selectedProductValue: selectedProductItem && selectedProductItem.id,
      authUrl: this.props.gatewayToEdit && this.props.gatewayToEdit.keycloak && this.props.gatewayToEdit.keycloak.authurl,
      realm: this.props.gatewayToEdit && this.props.gatewayToEdit.keycloak && this.props.gatewayToEdit.keycloak.realm,
      realmClient: this.props.gatewayToEdit && this.props.gatewayToEdit.keycloak && this.props.gatewayToEdit.keycloak.realmClient,
      baseUrl: this.props.gatewayToEdit && this.props.gatewayToEdit.zendesk && this.props.gatewayToEdit.zendesk.baseUrl,
      defaultUrl: this.props.gatewayToEdit && this.props.gatewayToEdit.zendesk && this.props.gatewayToEdit.zendesk.endpoints[0].url,
      supportUrl: this.props.gatewayToEdit && this.props.gatewayToEdit.zendesk && this.props.gatewayToEdit.zendesk.endpoints[1].url,
      faqUrl: this.props.gatewayToEdit && this.props.gatewayToEdit.zendesk && this.props.gatewayToEdit.zendesk.endpoints[2].url
    });
  }
  render() {
    const notification = this.props.errorMessage !== "" ?
      <div>
        <InlineNotification
          kind="error"
          title=""
          subtitle={this.props.errorMessage.join('; ')}
          iconDescription="close"
        />
      </div>
      : <div></div>;
    return (
      <div className='popup'>
        <div className='popup_inner'>
          <div className="modal-content">
            <span className="close" onClick={this.props.closePopup}>&times;</span>
            <h2>{this.props.gatewayToEdit && this.props.gatewayToEdit.clientName ? "Edit Configuration" : "Add Configuration"}</h2>
            <hr className="hrStyle" />
            <div className="row">
              <div className="label_popup">Product
                            <span className="astriks">*</span></div>
              <div className="textbox_popup">
                <DropdownV2
                  id="ddProduct"
                  label=""
                  items={this.state.products}
                  itemToString={items => (items ? items.text : '')}
                  onChange={this.productNameChanged}
                  initialSelectedItem={this.state.selectedProduct}
                  selectedItem={this.state.selectedProductItem}
                  disabled={this.props.isEditable ? true : false}
                />
                <span className="validationError">{this.state.errors["productId"]}</span>
              </div>
            </div>
            <h4 className="h3-popup">Keycloak Details</h4>
            <div className="row">
              <div className="label_popup">Authentication URL
                            <span className="astriks">*</span> </div>
              <div className="textbox_popup">
                <TextInput
                  className="textbox_medium"
                  id="txtAuthUrl"
                  labelText=""
                  type="text"
                  defaultValue={this.props.gatewayToEdit && this.props.gatewayToEdit.keycloak && this.props.gatewayToEdit.keycloak.authurl}
                  onChange={this.handleAuthUrlChange}
                />
                <span className="validationError">{this.state.errors["authurl"]}</span>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="label_popup">Realm Name
                                <span className="astriks">*</span>
                <Icon
                  icon={iconInfoSolid}
                  fill='#001E60'
                  description='For multiple realms, please use comma separation. e.g Realm1, Realm2, ...'
                  className='label-info'>
                </Icon>
              </div>
              <div className="textbox_popup">
                <TextInput
                  className="textbox_medium"
                  id="txtRealm"
                  labelText=""
                  type="text"
                  placeholder="Realm1, Realm2, .."
                  onChange={this.handleRealmChange}
                  defaultValue={this.props.gatewayToEdit && this.props.gatewayToEdit.keycloak && this.convertArryToString(this.props.gatewayToEdit.keycloak.realm)}
                />
                <span className="validationError">{this.state.errors["realm"]}</span>
              </div>
            </div>
            <div className="row">
              <div className="label_popup">Realm Client
                            <span className="astriks">*</span> </div>
              <div className="textbox_popup">
                <TextInput
                  className="textbox_medium"
                  id="txtRealmClient"
                  labelText=""
                  type="text"
                  onChange={this.handleRealmClientChange}
                  defaultValue={this.props.gatewayToEdit && this.props.gatewayToEdit.keycloak && this.props.gatewayToEdit.keycloak.realmClient}
                />
                <span className="validationError">{this.state.errors["realmClient"]}</span>
              </div>
            </div>
            <h4 className="h3-popup">Zendesk Details</h4>
            <div className="row">
              <div className="label_popup">Zendesk Base URL
                            <span className="astriks">*</span> </div>
              <div className="textbox_popup">
                <TextInput
                  className="textbox_medium"
                  id="txtBaseUrl"
                  labelText=""
                  type="text"
                  onChange={this.handleBaseUrlChange}
                  defaultValue={this.props.gatewayToEdit && this.props.gatewayToEdit.zendesk && this.props.gatewayToEdit.zendesk.baseUrl}
                />
                <span className="validationError">{this.state.errors["baseUrl"]}</span>
              </div>
            </div>
            <fieldset>
              <legend>Endpoints</legend>
              <div className="row">
                <div className="label_popup">Default URL
                            <span className="astriks">*</span> </div>
                <div className="textbox_popup">
                  <TextInput
                    className="textbox_medium"
                    id="txtDefaultUrl"
                    labelText=""
                    type="text"
                    onChange={this.handleDefaultUrlChange}
                    defaultValue={this.props.gatewayToEdit && this.props.gatewayToEdit.zendesk
                      && this.props.gatewayToEdit.zendesk.endpoints && this.props.gatewayToEdit.zendesk.endpoints[0].url}
                  />
                  <span className="validationError">{this.state.errors["defaultUrl"]}</span>
                </div>
              </div>
              <div className="row">
                <div className="label_popup">Support URL
                            <span className="astriks">*</span> </div>
                <div className="textbox_popup">
                  <TextInput
                    className="textbox_medium"
                    id="txtSupportUrl"
                    labelText=""
                    type="text"
                    onChange={this.handleSupportUrlChange}
                    defaultValue={this.props.gatewayToEdit && this.props.gatewayToEdit.zendesk
                      && this.props.gatewayToEdit.zendesk.endpoints && this.props.gatewayToEdit.zendesk.endpoints[1].url}
                  />
                  <span className="validationError">{this.state.errors["supportUrl"]}</span>
                </div>
              </div>
              <div className="row">
                <div className="label_popup">FAQ URL<span className="astriks">*</span> </div>
                <div className="textbox_popup">
                  <TextInput
                    className="textbox_medium"
                    id="txtFaqUrl"
                    labelText=""
                    type="text"
                    onChange={this.handleFaqUrlChange}
                    defaultValue={this.props.gatewayToEdit && this.props.gatewayToEdit.zendesk
                      && this.props.gatewayToEdit.zendesk.endpoints && this.props.gatewayToEdit.zendesk.endpoints[2].url}
                  />
                  <span className="validationError">{this.state.errors["faqUrl"]}</span>
                </div>
              </div>
            </fieldset>
            <div className="row_button">
              {this.props.hideInfo ? null : notification}
              {this.props.configurationdetailsloading ? <div className="loader-inline"><InlineLoading description="Please wait..." /></div> : <div></div>}
              <Button className="cancelbutton" onClick={this.props.closePopup}>Cancel</Button>
              <Button className="savebutton" onClick={this.handleConfigurationSubmit}>Save</Button>              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddConfiguration.propTypes = {
  handleConfigurationSubmit: PropTypes.func,
  closePopup: PropTypes.func,
  isEditable: PropTypes.bool,
  gatewayToEdit: PropTypes.object,
  hideInfo: PropTypes.bool,
  productList: PropTypes.array
}

export default AddConfiguration;