import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { LOGOUT } from '../store/login/actionTypes';
import user from "../store/login/reducer";
import product from "../store/product/reducer";
import gatewayConfiguration from "../store/gatewayConfiguration/reducer";

const appReducer = combineReducers({
  router: routerReducer,
  user,
  product,
  gatewayConfiguration,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
