import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import GatewayConfiguration from "../../containers/gateway/gatewayConfiguration"
import product from "../../containers/product/product"
import Header from "../../containers/header/header";

import 'material-design-icons/iconfont/material-icons.css';
import './App.scss';


class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Header></Header>
          <div className="body_container">
            <Route exact path="/" component={GatewayConfiguration} />
            <Route exact path="/gwconfigurations" component={GatewayConfiguration} />
            <Route exact path="/products" component={product} />
          </div>
        </div>
      </Router>
   
    );
  }
}

export default App;
