import React, { Component } from "react";
import ListConfiguration from '../../components/gatewayConfiguration/listConfiguration';
import AddConfiguration from '../../components/gatewayConfiguration/addConfiguration';
import { getProductList } from '../../store/product/action';
import { Button, InlineNotification } from 'bonsai-components-react';
import "../../assets/stylesheets/style.scss";
import {
  getConfigurationList,
  saveConfiguration,
  updateConfiguration
} from '../../store/gatewayConfiguration/action';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './gatewayConfiguration.scss';

export class gatewayConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      showPopup: false,
      hideInfo: true,
      isEditable: false,
      gatewayToEdit: null,
      notificationMessage: "",
      successNotification: false,
    }
  }
  componentDidMount() {
    this.props.getConfigurationList();
    this.props.getProductList();
  }

  togglePopup = (popUpType) => {
    if (popUpType === "AddPopup") {
      this.setState({
        isEditable: false,
        gatewayToEdit: null,
      });
    }
    this.setState({
      showPopup: !this.state.showPopup,
      hideInfo: true,
    });
  }
  notificationMessage = () => {
    if (this.props.errorMessage === "") {
      this.setState({
        showPopup: !this.state.showPopup,
        successNotification: true
      });
    }
    else {
      this.setState({ successNotification: false });
    }
  }
  handleSave = async (data) => {
    let notificationMessageVal = "";
    this.setState({
      hideInfo: false
    })
    if (this.state.isEditable) {
      await this.props.updateConfiguration(data);
      this.notificationMessage();
      notificationMessageVal = "Configuration saved successfully.";
    }
    else {
      await this.props.saveConfiguration(data);
      this.notificationMessage();
      notificationMessageVal = "Configuration saved successfully.";
    }
    this.setState({ notificationMessage: notificationMessageVal });
  }
  handleEdit = (clientName) => {
    const { configurationList } = this.props;
    this.setState({
      gatewayToEdit: configurationList.find(item => item.clientName === clientName),
      isEditable: true,
      successNotification: false,
      notificationMessage: ""
    });
    this.togglePopup();
  }
  handleAdd = () => {
    this.setState({
      successNotification: false,
      notificationMessage: ""
    });
    this.togglePopup("AddPopup")
  }
  render() {
    return (
      <div>
        <Button id="btnAddConfig"
          onClick={this.handleAdd} className="add-btn">Add Configuration</Button>
        {
          this.state.successNotification && (<div className="successnotification-message">
            <InlineNotification
              kind="success"
              title={this.state.notificationMessage}
              iconDescription="close"
              subtitle=""
              hideCloseButton={true}
            />
          </div>)
        }
        <ListConfiguration configurationList={this.props.configurationList} onEditClick={this.handleEdit} >
        </ListConfiguration>
        {this.state.showPopup ?
          <AddConfiguration text='Close Me'
            closePopup={() => this.togglePopup()}
            handleConfigurationSubmit={this.handleSave}
            errorMessage={this.props.errorMessage}
            isEditable={this.state.isEditable}
            gatewayToEdit={this.state.gatewayToEdit}
            hideInfo={this.state.hideInfo}
            productList={this.props.productList}
            configurationdetailsloading={this.props.configurationdetailsloading}
            history={this.props.history}
          />
          : false
        }
      </div>
    );
  }
}

gatewayConfiguration.propTypes = {
  getConfigurationList: PropTypes.func,
  saveConfiguration: PropTypes.func,
  updateConfiguration: PropTypes.func,
  getProductList: PropTypes.func,
};

gatewayConfiguration.defaultProps = {
  getConfigurationList: () => { },
  saveConfiguration: () => { },
  updateConfiguration: () => { },
  getProductList: () => { },
}

const mapDispatchToProps = {
  getConfigurationList,
  saveConfiguration,
  updateConfiguration,
  getProductList,
};

const mapStateToProps = ({ gatewayConfiguration, product }) => {
  const { configurationList, configurationListLoading,configurationdetailsloading, errorMessage } = gatewayConfiguration;
  const { productList, productListLoading } = product;
  return {
    configurationList,
    configurationListLoading,
    errorMessage,
    productList,
    productListLoading,
    configurationdetailsloading
  }

};
export default connect(mapStateToProps, mapDispatchToProps)(gatewayConfiguration);